<template>
    <!--  Content Start -->
    <div class="sabc_about_outer">
      <div class="container">
          <div class="row">
              <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                <div class="sabc_about_inner">
                  <h1>About SABC</h1>
                  <img :src="require('@/assets/images/sabc_about_pic.webp')" alt="sabc_about_pic" height="360" width="870"/>
                  <h2>
                    History
                  </h2>
                  <p>
                    Established in August 1936, The South African Broadcasting Corporation (SOC) Limited (“The SABC”) is a public broadcaster (Schedule 2: “Major Public Entity” in terms of the Public Finance Management Act No. 1 of 1999, “PFMA”), with a mandate to inform, educate and entertain the public of South Africa. Founded on the statute of The Broadcasting Act (The Act) the SABC’s obligations are captured in the Independent Communications Authority of South Africa (“ICASA”) Regulations and license conditions, providing five television channels and 18 Radio stations.
                  </p>
                  <h2>Our Vision</h2>
                  <p>To become the leading, credible voice and face of the nation and the continent.</p>
                  <h2>Our Mission</h2>
                  <p>
                    A high performing, financially sustainable, digitized national public broadcaster that provides compelling informative, educational and entertaining content via all platforms.
                  </p>
                  <h2>Our Values</h2>
                  <ul>
                    <li>
                      <span>
                        Respect
                      </span>
                    </li>
                    <li>
                      <span>
                        Trust
                      </span>
                    </li>
                    <li>
                      <span>
                        Integrity
                      </span>
                    </li>
                    <li>
                      <span>
                        Quality
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <SideBar :editorsPick="editorsPick" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
          </div>
      </div>
    </div>
    <!--  Content end -->
</template>

<script>
    import SideBar from '@/components/common/sidebar/sidebar';

    export default {
        name: 'about',
        data() {
            return {
                editorsPick: [],
                bannerImages : [],
                bannerEmbed : [],
            }
        },
        components: {SideBar},
        mounted() {
            this.getAboutPageData();
        },
       watch: {
       },
        serverPrefetch() {
            this.getAboutPageData();
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.about_sabc_sport_meta_title);
                let description = this.$getSafe(() => ss.about_sabc_sport_meta_description);
                let keywords = this.$getSafe(() => ss.about_sabc_sport_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }

        },

        methods: {
            getAboutPageData(offset) {
                this.url = this.$GetAboutPageData;
                return this.$axios.get(this.url)
                        .then(response => {
                            if (response.data) {
                                this.editorsPick = response.data.editorsPick;
                                this.bannerImages = response.data.bannerImages;
                                this.bannerEmbed = response.data.bannerEmbed;
                                // this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/about.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>
